import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ICONS from "../icons"
import { Typography } from "@material-ui/core"
interface IAuthor {
  name: string
}

interface ISite {
  title: string
  author: IAuthor
  social: ISocialMedias
}

interface ISocialMedias {
  twitter: string
  linkedin: string
  github: string
  email: string
}

interface ISocial {
  site: ISite
}

const useStyles = makeStyles(() => ({
  social: {
    display: "inline-block",
    width: 20,
    height: 20,
    strokeWidth: 0,
    stroke: "var(--color-text)",
    fill: "var(--color-text)",
    fontStyle: "normal",
    fontWeight: "normal",
    marginRight: ".5em",
    textAlign: "center",
    fontVariant: "normal",
    textTransform: "none",
    lineHeight: ".3em",
    marginLeft: ".5em",
    webkitFontSmoothing: "antialiased",
    mozOsxFontSmoothing: "grayscale",
  },
  socials: {
    position: "fixed",
    right: "25%",
    bottom: 0,
  },
  author: {
    textAlign: "left",
    left: "25%",
    bottom: 0,
    position: "fixed",
  },
  footer: {
    display: "flex",
  },
}))

export const FooterSocialIcons = () => {
  const socialMedia: ISocial = {
    site: {
      title: "calculator",
      author: {
        name: "Lucas Neiva",
      },
      social: {
        twitter: "https://twitter.com/lucasneiva7",
        github: "https://github.com/Neiva07",
        email: "https://lucasneiva07@gmail.com",
        linkedin: "https://linkedin.com/in/lucas-neiva",
      },
    },
  }

  const classes = useStyles()

  const {
    site: { social },
  } = socialMedia

  return (
    <div className={classes.footer}>
      <div className={classes.author}>
        <Typography size="24">
          Created by{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://linkedin.com/in/lucas-neiva"
          >
            Lucas Neiva
          </a>
        </Typography>
      </div>
      <div className={classes.socials}>
        {Object.entries(social).map(([name, url], index) => {
          const contact = getIcon(name)

          return (
            <a key={name} href={url} rel="noopener noreferrer" target="_blank">
              <svg viewBox={contact.viewBox} className={classes.social}>
                <title>{name}</title>
                <path d={contact.path} />
              </svg>
            </a>
          )
        })}
      </div>
    </div>
  )
}

const getIcon = (name: string) => {
  let icon

  switch (name) {
    case "twitter":
      icon = ICONS.TWITTER
      break
    case "github":
      icon = ICONS.GITHUB
      break
    case "vkontakte":
      icon = ICONS.VKONTAKTE
      break
    case "telegram":
      icon = ICONS.TELEGRAM
      break
    case "email":
      icon = ICONS.EMAIL
      break
    case "rss":
      icon = ICONS.RSS
      break
    case "linkedin":
      icon = ICONS.LINKEDIN
      break
    case "instagram":
      icon = ICONS.INSTAGRAM
      break
    case "line":
      icon = ICONS.LINE
      break
    case "facebook":
      icon = ICONS.FACEBOOK
      break
    case "gitlab":
      icon = ICONS.GITLAB
      break
    case "weibo":
      icon = ICONS.WEIBO
      break
    default:
      icon = {}
      break
  }

  return icon
}
